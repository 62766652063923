/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  background-color: #f8f8f8;
}

body {
  margin: 0;
  font-family: "Helvetica", "Noto Sans JP", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
  overflow-x: hidden;
  background-color: #f8f8f8;
}

html {
  scroll-behavior: smooth;
}

.wrapper {
  display: flex;
  padding-top: 60px;
}

.content {
  width: 100%;
  flex-direction: column !important;
  overflow-x: hidden;
}

.slick-dots li {
  height: 5px !important;
  margin: 0 25px !important;
}

.slick-dots li button {
  width: 55px !important;
  height: 5px !important;
  background: #000 !important;
  padding: 0 !important;
}

.slick-dots li button:before {
  content: none !important;
}

.slick-dots li.slick-active button {
  background-color: #e60012 !important;
}

.slick-prev {
  left: -7px !important;
  top: 33% !important;
  z-index: 999;
  font-size: 0px !important;
}

.slick-next {
  right: -7px !important;
  top: 33% !important;
  z-index: 999;
  font-size: 0px !important;
}

.slick-disabled {
  opacity: 0 !important;
  pointer-events: none;
}

.slick-prev:before,
.slick-next:before {
  border-radius: 100%;
  background: #fff !important;
  color: #e60012 !important;
  font-size: 29px !important;
  /* content: '' !important; */
}

.hero-slider .slick-arrow {
  display: none !important;
}

.slick-track {
  margin-left: 0 !important;
}

hr {
  z-index: auto;
}

.ytp-chrome-top .ytp-show-cards-title {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .slick-dots li {
    height: 5px !important;
    margin: 0 13px !important;
  }

  .slick-dots li button {
    width: 35px !important;
    height: 5px !important;
    background: #000 !important;
    padding: 0 !important;
  }
}

.slick-prev,
.slick-next {
  height: 28px;
  width: 28px;
}


.relative {
  position: relative;

}

.overlay {
  position: absolute;
  top: 0;
  right:0;
  background-color: #000;
  /* Black see-through */
  color: #f1f1f1;
  width: 15%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0.7;
  height: 100%;
  display: flex;
  justify-content: center; /* Horizontally center the child elements */
  align-items: center; /* Vertically center the child elements */
  flex-direction: column;
  gap: 15px;
}

.live-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.overlay-live {
  text-align: left;
  font-weight: bold;
}

.red-dot {
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
}

.overlay-count {
  font-size: 1.0rem;
  font-weight: bold;
}

.play-icon {
  font-size: 1.7rem;
  margin-top: 2rem
}